import React, {Suspense} from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.css';
import "./styles/main.scss";
import reportWebVitals from './reportWebVitals';
import {createBrowserRouter, Navigate, RouterProvider} from "react-router-dom";
import WebSocketProvider from './js/websocket-provider.js';
import moment from "moment";
import 'moment/locale/et';
import 'moment/locale/fi';
import {GoogleOAuthProvider} from '@react-oauth/google';

// Redux
import {applyMiddleware, createStore} from 'redux';
import thunk from 'redux-thunk';
import {Provider} from 'react-redux';
import rootReducer from './js/reducers';
import {
    getAccess,
    getBanners,
    getFamilyMembers,
    getFavoriteAuthors,
    getNotifications,
    getProducts,
    getUserData,
    getUserLevels,
    getUserRecipes
} from "./js/actions";
import {
    config,
    getCookie,
    isApp,
    isDomain,
    isLoggedIn,
    ReactNativeMessage,
    redirect,
    trackEvent,
    trackUser
} from "./js/helpers";
import loadable from '@loadable/component';
import {retry} from "./js/lazy-retry";

import "./js/plugins/leadfeeder.js";
import "./js/plugins/facebook.js";
import "./js/plugins/amplitude.js";
import "./js/plugins/onesignal.js";
import "./js/plugins/hotjar.js";
import "./js/plugins/helpcrunch.js";
import "./js/i18n.js";
import "./js/plugins/facebook-pixel.js";
import "./js/plugins/delighted.js";
import qs from "qs";
import ReactGA from "react-ga4";
import * as Sentry from "@sentry/react";
import FitlapChallenge from "./js/routes/fitlap-challenge";
import AdventCalender from "./js/routes/advent-calender";

const FitlapPlus = loadable(() => retry(() => import("./js/routes/fitlap-plus-view")));
const ErrorPage = loadable(() => retry(() => import("./js/error-page")));
const Root = loadable(() => retry(() => import("./js/routes/root.jsx")));
const MealPlan = loadable(() => retry(() => import("./js/routes/meal-plan")));
const Diary = loadable(() => retry(() => import("./js/routes/diary")));
const Goals = loadable(() => retry(() => import("./js/routes/goals")));
const ShoppingList = loadable(() => retry(() => import("./js/routes/shopping-list")));
const Recipes = loadable(() => retry(() => import("./js/routes/recipes")));
const Login = loadable(() => retry(() => import("./js/routes/login")));
const MealPlanReport = loadable(() => retry(() => import("./js/routes/meal-plan-report")));
const PersonalDetails = loadable(() => retry(() => import("./js/routes/personal-details")));
const PaymentHistory = loadable(() => retry(() => import("./js/routes/payment-history")));
const Affiliate = loadable(() => retry(() => import("./js/routes/affiliate")));
const Freeware = loadable(() => retry(() => import("./js/routes/freeware")));
const MealSettings = loadable(() => retry(() => import("./js/routes/meal-settings")));
const FamilyMembers = loadable(() => retry(() => import("./js/routes/family-members")));
const FamilyMember = loadable(() => retry(() => import("./js/routes/family-member")));
const Faq = loadable(() => retry(() => import("./js/routes/faq")));
const HomeCooks = loadable(() => retry(() => import("./js/routes/home-cooks")));
const HomeCookProfile = loadable(() => retry(() => import("./js/routes/home-cook-profile")));
const Blog = loadable(() => retry(() => import("./js/routes/blog")));
const PostEditor = loadable(() => retry(() => import("./js/components/blog/post-editor")));
const MyProfile = loadable(() => retry(() => import("./js/components/profile/my-profile")));
const GenerateRecipe = loadable(() => retry(() => import("./js/routes/generate-recipe")));
const Training = loadable(() => retry(() => import("./js/routes/training")));
const TrainingDiary = loadable(() => retry(() => import("./js/routes/training-diary")));
const ThankYou = loadable(() => retry(() => import("./js/routes/thank-you")));
const Subscription = loadable(() => retry(() => import("./js/routes/subscription")));
const ForgotPassword = loadable(() => retry(() => import("./js/routes/forgot-password")));
const Signup = loadable(() => retry(() => import("./js/routes/signup")));
const FreeSignup = loadable(() => retry(() => import("./js/routes/free-signup")));
const ShoppingListPublic = loadable(() => retry(() => import("./js/routes/shopping-list-public")));
const HelpcrunchWidget = loadable(() => retry(() => import("./js/components/widgets/helpcrunch-widget.jsx")));
const DelightedWidget = loadable(() => retry(() => import("./js/components/widgets/delighted-widget.jsx")));
const SingleMeal = loadable(() => retry(() => import('./js/routes/meal.jsx')));
const ResetPassword = loadable(() => retry(() => import("./js/routes/reset-password")));
const RequestErrorPage = loadable(() => retry(() => import("./js/request-error-page")));
const FitlapWrapped = loadable(() => retry(() => import("./js/routes/fitlap-wrapped")));
const Chatroom = loadable(() => retry(() => import("./js/routes/chatroom")));
const RecipeDemoPage = loadable(() => retry(() => import("./js/routes/recipe-demo-page")));
const store = createStore(rootReducer, applyMiddleware(thunk));
const root = ReactDOM.createRoot(document.getElementById('root'));
const defaultLang = getCookie('lang');

if (config('sentry')) {
    Sentry.init({
        dsn: "https://a6d063c09570eab1c4ce872619d418d2@o4506121398583296.ingest.sentry.io/4506121400090624",
        integrations: [
            new Sentry.BrowserTracing({
                // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
                tracePropagationTargets: ["localhost", /^https:\/\/api\.fitlap\/.ee/, "api.fitlap.ee"],
            }),
            new Sentry.Replay({
                networkDetailAllowUrls: ["localhost", /^https:\/\/api\.fitlap\/.ee/, "api.fitlap.ee"],
            }),
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, // Capture 100% of the transactions

        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
}

moment().locale(defaultLang)

const LoginRoute = ({children}) => {
    // Redirect user that is already logged in
    let loggedRedirectUrl = qs.parse(window.location.search)['?redirect'];

    if (loggedRedirectUrl && isLoggedIn()) {
        redirect(config('public_app_url') + loggedRedirectUrl);
        return;
    }

    return (!isLoggedIn() ? (
        <Login/>
    ) : (
        <Navigate to="/meal-plan" />
    ))
}

const router = createBrowserRouter([
    {
        path: "/",
        element: isLoggedIn() ? <Root/> : <Navigate to="/login" />,
        errorElement: <Root><ErrorPage/></Root>,
        children: [
            {
                path: "/",
                element: isLoggedIn() ? <Navigate to={isDomain('com') ? "/meal-plan-simple" : "/meal-plan"} /> : <Navigate to="/login" />,
            },
            {
                path: "meal-plan",
                element: <MealPlan/>,
            },
            {
                path: "diary",
                element: <Diary/>,
            },
            {
                path: "goals",
                element: <Goals/>,
            },
            {
                path: "shopping-list",
                element: <ShoppingList/>,
            },
            {
                path: "cookbook",
                element: <Recipes/>,
            },
            {
                path: "meal/:id",
                element: <SingleMeal/>,
            },
            {
                path: "profile",
                element: <MyProfile/>,
            },
            {
                path: "meal-plan/report",
                element: <MealPlanReport/>,
            },
            {
                path: "fitlap-wrapped",
                element: <FitlapWrapped/>,
            },
            {
                path: "settings",
                children: [
                    {path: "personal", element: <PersonalDetails/>},
                    {path: "meals", element: <MealSettings/>},
                    {path: "family", element: <FamilyMembers/>},
                    {path: "family/member", element: <FamilyMember/>},
                    {path: "family/member/:id", element: <FamilyMember/>},
                    {path: "subscription", element: <Subscription/>},
                    {path: "freeware", element: <Freeware/>},
                    {path: "payments", element: <PaymentHistory/>  },
                    {path: "affiliate", element: <Affiliate/>},
                    {path: "itunes", element: <Subscription/>},
                ]
            },
            config('feature.fitlap_plus') && {path: "fitlap-plus", element: <FitlapPlus/>},
            {
                path: "blog",
                element: <Blog/>,
            },
            {
                path: "testimonials",
                element: '',
            },
            {
                path: "faq",
                element: <Faq/>,
            },
            {
                path: "home-cooks",
                element: <HomeCooks/>,
            },
            {
                path: "home-cooks/:profile",
                element: <HomeCookProfile/>,
            },
            {
                path: "post",
                element: <PostEditor/>,
            },
            {
                path: "post/:id",
                element: <PostEditor/>,
            },
            {
                path: "calculator",
                element: <GenerateRecipe/>,
            },
            {
                path: "calculator/:id",
                element: <GenerateRecipe/>,
            },
            {
                path: "training",
                element: <Training/>,
            },
            {
                path: "training/:id",
                element: <Training/>,
            },
            {
                path: "fitlap-challenge",
                element: <FitlapChallenge/>
            },
            {
                path: "training-diary",
                element: <TrainingDiary/>
            },
            {
                path: "christmas",
                element: <AdventCalender/>
            },
            {
                path: "meal-plan-simple",
                element: <RecipeDemoPage/>
            },
            config('feature.fitlap_chat') && {path: "chatroom", element: <Chatroom/>},
        ],
    },
    {
        path: "thank-you",
        element: <ThankYou/>,
    },
    {
        path: "forgot-password",
        element: <ForgotPassword/>,
    },
    {
        path: "signup",
        element: !isLoggedIn() ? <Signup/> : <Navigate to="/meal-plan" />,
    },
    {
        path: "free-signup",
        element: !isLoggedIn() ? <FreeSignup/> : <Navigate to="/meal-plan" />,
    },
    {
        path: "/login",
        element: <LoginRoute/>,
        errorElement: <ErrorPage/>,
    },
    {
        path: "shopping-list-public/:id",
        element: <ShoppingListPublic/>,
    },
    {
        path: "reset-password/",
        element: <ResetPassword/>
    },
    {
        path: "/failed-to-load",
        element: <RequestErrorPage/>
    }
]);

const AppInitialLoad = () => {
    // Always load products from API.
    store.dispatch(getProducts());

    // Disable app refresh
    ReactNativeMessage('DISABLE_REFRESH');

    // Initialize GA 4
    if (config('analytics_id')) {
        ReactGA.initialize([{trackingId: config('analytics_id')}]);

        // Allow all cookies
        window.gtag('consent', 'update', {
            'ad_user_data': 'granted',
            'ad_personalization': 'granted',
            'ad_storage': 'granted',
            'analytics_storage': 'granted'
        });
    }

    // Amplitude
    if (config('amplitude')) {
        window.amplitude.getInstance().init(config('amplitude'), null, {
            includeUtm: true,
            includeReferrer: true,
            includeGclid: true
        });

        trackUser({
            domain: config('domain')
        });

        setTimeout(() => {
            let isApp = getCookie('is_app');
            let device = getCookie('device');
            let appVersion = getCookie('app_version');

            trackEvent('app_info', {
                'is_app': isApp,
                'device': device,
                'app_version': appVersion,
            });
        }, 5000)
    }


    // Always load user data and access on App refresh for logged in users
    if (isLoggedIn()) {
        store.dispatch(getUserData());
        store.dispatch(getAccess());
        store.dispatch(getFamilyMembers());
        store.dispatch(getNotifications());
        store.dispatch(getFavoriteAuthors());
        store.dispatch(getUserRecipes());
        store.dispatch(getUserLevels());

        if (config('feature.banner')) {
            store.dispatch(getBanners());
        }

        // Helpcrunch
        if (config('helpcrunch.application_name') && window.HelpCrunch) {
            const helpCrunchOptions = {
                applicationId: config('helpcrunch.application_id'),
                applicationSecret: config('helpcrunch.application_secret')
            };

            // Init chat for guests
            window.HelpCrunch('init', config('helpcrunch.application_name'), helpCrunchOptions);
            window.HelpCrunch('showChatWidget');

            // Set chat window on top of everything else
            window.HelpCrunch('onChatOpen', () => {
                document.querySelectorAll('.helpcrunch-iframe-wrapper iframe')[0].style.setProperty('z-index', '1000', 'important');
            });

            // Return to normal
            window.HelpCrunch('onChatClose', () => {
                document.querySelectorAll('.helpcrunch-iframe-wrapper iframe')[0].style.setProperty('z-index', '100', 'important');
            });
        }
    }
}

AppInitialLoad();

const renderLoader = () => {
    return (
        <div className="loading-icon">
            <div className="loading-spinner">
                <div className="loading-spinner-dot"></div>
                <div className="loading-spinner-dot"></div>
                <div className="loading-spinner-dot"></div>
                <div className="loading-spinner-dot"></div>
                <div className="loading-spinner-dot"></div>
                <div className="loading-spinner-dot"></div>
            </div>
        </div>
    );
}

root.render(
    <Suspense fallback={renderLoader()}>
        <Provider store={store}>
            <WebSocketProvider>
                <GoogleOAuthProvider
                    clientId="209668934326-tq06ph5s7sgsaageonpfoq51roqdc7bp.apps.googleusercontent.com">
                    <RouterProvider fallbackElement={"Loading..."} router={router}/>
                    <HelpcrunchWidget/>
                    <DelightedWidget/>
                </GoogleOAuthProvider>
            </WebSocketProvider>
        </Provider>
    </Suspense>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
